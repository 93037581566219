// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { RedocStandalone } from 'redoc';

function App() {
  return (
    <RedocStandalone
          specUrl={'/swagger/swagger.json'}
          // specUrl={require('swagger.json')}
          // specUrl="https://petstore.swagger.io/v2/swagger.json"
          // specUrl="http://localhost:8080/api-docs"
          options={{
            nativeScrollbars: true,
            theme: { colors: { primary: { main: '#dd5522' } } },
          }}
        />
  );
}

export default App;
